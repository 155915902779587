
import GTMEvents from '../data/gtmEvents.json';
import { environment } from 'src/environments/environment';

/*
* Send Google Tag Manager Events
*/
const dataLayer = window['dataLayer'];
const prod = (!(window.location.host.indexOf('localhost') > -1));

export function sendGTMEvent(id: any): void {
    const event = GTMEvents.find(ev => ev.id === id);
    if (event && prod) {
        dataLayer.push(event);
    } // END IF
    console.log('sendGTMEvent', id, event);
}

export function sendHCPEvent(bHCP: boolean): void {
    const event = {
        event: 'hcp',
        eventCategory: (bHCP) ? 'Yes' : 'No',
        eventAction: '',
        eventLabel: ''
    };
    console.log('sendHCPEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

export function sendCountryEvent(sCountry: string): void {
    const event = {
        event: 'country_select',
        eventCategory: sCountry,
        eventAction: '',
        eventLabel: ''
    };
    console.log('sendCountryEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

/**
 * Track a quiz answer
 * @param args
 */
export function sendQuizEvent(question: string, answer: string): void {
    const event = {
        event: "quiz",
        eventCategory: question,
        eventAction: answer,
        eventLabel: ""
    };
    console.log('sendQuizEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

/**
 * Send a media tracking event
 * @param type "pdf" or "video"
 * @param sectionName 
 * @param title 
 * @param url 
 */
export function sendMediaEvent(type: string, sectionName: string, title: string, url: string): void {
    const event = {
        event: type + "View",
        eventCategory: sectionName,
        eventAction: title,
        eventLabel: url
    };
    console.log('sendMediaEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

/**
 * Send an external navigation link tracking event
 * @param title 
 * @param url 
 */
export function sendExternalLinkEvent(title: string, url: string) {
    const event = {
        event: "outboundLink",
        eventCategory: title,
        eventAction: url,
        eventLabel: ""
    };
    console.log('sendExternalLinkEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

/**
 * Send hostpot tracking event
 * @param title 
 */
export function sendHotspotEvent(title: string) {
    const event = {
        event: "hotspot",
        eventCategory: title,
        eventAction: "",
        eventLabel: ""
    };
    console.log('sendHotspotEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}

/**
 * Send navigation tracking event
 * @param title 
 */
 export function sendNavigationEvent(title: string) {
    const event = {
        event: "navigation",
        eventCategory: title,
        eventAction: "",
        eventLabel: ""
    };
    console.log('sendNavigationEvent', event);
    if (prod) {
        dataLayer.push(event);
    }
}