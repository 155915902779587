import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../services/modal.service';
import Modals from '../data/modals.json';
import videos from '../data/videos.json';
import { ThrowStmt } from '@angular/compiler';
import { TweenMax } from 'gsap';
import { environment } from 'src/environments/environment';
import * as GTM from '../utils/GTM';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public modalVisible: boolean = false;
  public path: string = environment.assetBasePath + 'pdfs/';

  @ViewChild('modal-contain') _modal: ElementRef;
  public modalData: any = undefined;
  public videoListData: any = undefined;
  public activeVideo: string = undefined;
  public showPdf: boolean = false;
  public blockEvent: boolean = false;
  public iframeSource: any;

  public twilioChatRef: any;

  constructor(
    private _ms: ModalService,
    private _sanitizer: DomSanitizer,
    ) { }

  ngOnInit(): void {
    this.twilioChatRef = window['twilioChat'];
    this.subscribe();
  }

  public subscribe(): void {
    this._ms.modalVisible.subscribe(data => {


      if (this.modalVisible !== data) {
        this.modalVisible = data;

        if (this.modalVisible) {
          this.sendGTM();
        }
      } // END IF

      if (this.twilioChatRef) {
        if (window['jQuery']) {
          this.modalVisible ? this.twilioChatRef.close() : this.twilioChatRef.open();
        }
      } // END IF

    });
    this._ms.blockEvent.subscribe(data => {
      this.blockEvent = data;
    });

    this._ms.modalData.subscribe(data => {
      if (this.modalData !== data) {
        this.modalData = data;

        if (this.modalData?.modal_type === 'iframe') {
          this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl(
            this.modalData.src
          );
        }

        if (this.modalData?.modal_type === 'video-menu') {
          this.videoListData = videos.find(el => el.id === this.modalData.id);
        }

        if (this.modalData?.modal_type === 'pdf') {
          this.showPdf = false;
          setTimeout(() => {
            this.showPdf = true;
          }, 0);
          // this.modalData.pdfSrc = this.modalData.pdfSrc;
        }
        if (this.modalVisible) {
          this.sendGTM();
        }
      }

    });
  }

  /**
   * Sent GTM event
   * - Use modalData gtm_id when found
   * - Detect modal type media and send a media event
   */
  public sendGTM() {
    // Send GTM event when modal is shown
    if (this.modalData?.gtm_id) {
      GTM.sendGTMEvent(this.modalData.gtm_id);
    } else if (this.modalData?.modal_type) {
      const parentData = this.modalData?.parent ? Modals.find(modal => modal.id === this.modalData?.parent) : null;
      const sectionName = parentData ? parentData?.title : this.modalData?.title
      // console.log(this.modalData, parentData, "is the modal for GTM event");
      switch (this.modalData.modal_type) {
        case "pdf":
          GTM.sendMediaEvent(
            "pdf",
            sectionName, 
            this.modalData?.title ? this.modalData.title : this.modalData?.id,
            this.modalData?.pdfSrc
          );
        break;
        case "video-player":
          GTM.sendMediaEvent(
            "video",
            sectionName, 
            this.modalData?.title ? this.modalData.title : this.modalData?.id,
            this.modalData?.path ? (environment.assetBasePath + 'videos/' + this.modalData.path) : ""
          );
        break;
        default:
          // Do nothing
      }
    }
  }

  public closeClick(parent = undefined): void {
    this.showPdf = false;

    // console.log(parent, "is the parent id");
    if (parent) {
      // this.modalData = ;
      this._ms.modalData.next(Modals.find(modal => modal.id === parent));
    } else {
      this.modalVisible = false;
      this._ms.modalVisible.next(false);
      this._ms.modalData.next(undefined);
    }
  }

  public subMenuClick(item: any, parent: any): void {
    // console.log(item, parent, "subMenuClick");
    if (item.modal) {
      const data: any = Modals.find(el => el.id === item.modal);
      data.parent = parent;
      if (item.payload) {
        data.payload = item.payload;
      }
      this._ms.modalData.next(data);
    } else if (item.href) {
      // @analytics
      GTM.sendGTMEvent(item.gtm_id);
      window.open(item.href);
    } else if (item.type === "mirf") {
      this._ms.mirf(true);
    }
  }

  public columnClick(e, col, parent): void {
    e.preventDefault();

    let data;

    if (col.pdf_path) {
      data = {
        parent: parent,
        modal_type: 'pdf',
        pdfSrc: this.path + col.pdf_path,
        gtm_id: (col.gtm_id) ? col.gtm_id : null
      }
    } else if (col.video_player) {
      const vids = videos.find(mod => mod.id === col.video_player);
      const curVid = vids.videos.find(mod => mod.id === col.video_id);
      data = {
        active_video: curVid,
        modal_type: 'video-player',
        id: col.video_id,
        parent
      };
    }
    else {
      data = Modals.find(el => el.id === col.modal);
      if (parent) {
        data.parent = parent;
      } // END IF
    }

    // console.log(data);

    this._ms.modalData.next(data);
  }

  public imageHotSpotClick(e): void {
    if (this.modalData?.hotSpots) {
      // console.log('imageHotSpotClick, e', e);
      // console.log('imageHotSpotClick, OFFSET', e.offsetX, e.offsetY);
      // console.log('imageHotSpotClick, spots', this.modalData.hotSpots);
      const ratio = e.target.width / 1541;
      const oX = e.offsetX / ratio;
      const oY = e.offsetY / ratio;
      this.modalData.hotSpots.forEach(spot => {
        // console.log('SPOT', spot.x, spot.y, spot.w, spot.h, ratio, oX, oY);

        if (oX > spot.x && oX < spot.x + spot.w) {
          if (oY > spot.y && oY < spot.y + spot.h) {
            // console.log('WE CLICKED IT!!!', spot.id);

            const data = {
              ...spot,
              parent: this.modalData.id
            }
            this._ms.modalData.next(data);
          }
        }
      });

    }

  }

  public exitClick(): void {
    window.location.href = 'https://virtualweek.ueg.eu/booth/08';
  }
  
}
