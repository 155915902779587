import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import countries from '../data/countryList.json';
import { PhotosphereService } from '../services/photosphere.service';
import { ModalService } from '../services/modal.service';
import Modals from '../data/modals.json';
import * as GTM from '../utils/GTM';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @Output() closeWelcomeOutput = new EventEmitter();

  redirectUrl = 'https://www.takeda.com/';
  redirectUrlTitle = 'Takeda';
  showRedirect = false;
  showRedirect2 = false;
  showHealthcare = true;
  showCountry = false;
  public validCountries = countries;
  public selectedCountry = '';
  showWelcome = true;
  showContinue = false;

  constructor(private _ps: PhotosphereService, private _ms: ModalService, private window: Window) {
    this._ps.interactable.next(!this.showWelcome);
  }

  ngOnInit(): void {
  }

  public setRedirect(e): void {
    e.preventDefault();

    this.showRedirect = true;
    setTimeout(() => { 
      this.showRedirect2 = true;
      GTM.sendExternalLinkEvent(this.redirectUrlTitle, this.redirectUrl);
      setTimeout(() => {
        window.location.href = this.redirectUrl;
      }, 1000);
    }, 5000);
    GTM.sendHCPEvent(false);
  }

  public setCountryPrompt(e): void {
    e.preventDefault();
    this.showHealthcare = false;
    this.showCountry = true;
    GTM.sendHCPEvent(true);
  }

  public closeWelcome(e): void {
    e.preventDefault();
    GTM.sendCountryEvent(this.selectedCountry);
    this.showWelcome = false;
    this._ps.interactable.next(true);
    this.closeWelcomeOutput.emit();
    this._ms.modalVisible.next(true);
    this._ms.modalData.next(Modals.find(modal => modal.id === "about-takeda"));
  }

  public setContinue(event): void {
    this.showContinue = event.target.value !== 'none';
    this.selectedCountry = event.target.value;
  }

  public openLink(event: any) {
    event.preventDefault();
    GTM.sendExternalLinkEvent(this.redirectUrlTitle, this.redirectUrl);
    window.location.href = this.redirectUrl;
  }

}
