import { Component, OnInit } from '@angular/core';
import { PhotosphereService } from '../services/photosphere.service';
import { ModalService } from '../services/modal.service';
import { HeaderService } from '../services/header.service';
import Photospheres from '../data/photospheres.json';
import Hotspots from '../data/hotspotData.json';
import Modals from '../data/modals.json';
import Videos from '../data/videos.json';
import * as GTM from '../utils/GTM';

enum HeaderState {
  Home = 'home-state',
  Research = 'research-state',
  Ulcer = 'ulcer-state',
  ShortBowel = 'short-bowel-state',
  Perianal = 'perianal-state'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public giDirection = false;
  public researchDirection = false;
  public isModalOpen = false;
  public state: any;
  public modal: string;
  public showHomeButton: boolean = false;
  public useCustomHeader: boolean = true;

  currentModal: any;
  currentPhotosphere: any;



  constructor(private _ps: PhotosphereService, private _ms: ModalService, private _hs: HeaderService) { }

  ngOnInit(): void {
    this.subscribe();
  }

  subscribe(): void {
    this._ms.modalData.subscribe(data => {
      this.currentModal = data;
      if (this.currentModal) {
        // console.log(this.currentModal);
        this.modal = this.currentModal.id;
        this.setState();
        // console.log(this.modal);
      }
    });
    this._ms.modalVisible.subscribe(data => {
      this.isModalOpen = data;
    });
    this._ps.currentPhotosphere.subscribe(data => {
      this.currentPhotosphere = data;
      this.setState();
    });
    this._ps.currentRotation.subscribe(data => {
      if (data > 160 && data < 300) {
        this.researchDirection = true;
        this.giDirection = false;
      }
      else {
        this.giDirection = true;
        this.researchDirection = false;
      }
      this.setState();
    });
  }

  setState() {
    const researchModals = ['pipeline', 'celiac', 'clinicalTrials'];
    const ulcerModals = ['ulcerativeColitis', 'pathophysiologyUcCd', 'vedolizumab', 'verdict'];
    const shortBowelModals = ['shortBowel', 'intestinalFunction', 'postParenteral', 'teduglutide'];
    const perianalModals = ['chronsPerianal', 'pathophysiology', 'mesenchymal', 'ecco', 'inspire'];
    const pesModals = ['presentations', 'experts', 'symposia'];
    let pesOpen = false;
    if (this.isModalOpen && this.currentModal) {
      if (ulcerModals.includes(this.currentModal.id)) {
        this.state = HeaderState.Ulcer;
      }
      if (shortBowelModals.includes(this.currentModal.id)) {
        this.state = HeaderState.ShortBowel;
      }
      if (perianalModals.includes(this.currentModal.id)) {
        this.state = HeaderState.Perianal;
      }
      if (researchModals.includes(this.currentModal.id)) {
        this.state = HeaderState.Research;
      }
      if (pesModals.includes(this.currentModal.id)) {
        pesOpen = true;
      }
    }
    if (!this.isModalOpen || pesOpen) {
      this.state = HeaderState.Home;

      // if (this.currentPhotosphere.id === 'mainView' || !this.researchDirection) {
      //   this.state = HeaderState.Home;
      // }
      // if (this.currentPhotosphere.id === 'researchDevelopment' && this.researchDirection) {
      //   this.state = HeaderState.Research;
      // }
    }
    this._hs.headerState.next(this.state);
  }

  public goHome(): void {
    this._ms.modalVisible.next(false);
    this.setPhotosphere('hotspotGoToMain');
  }

  public setPhotosphere(hotspotId): void {
    const hotspot = Hotspots.find(hs => hs.id === hotspotId);
    const photosphereData = Photospheres.find(ps => ps.id === hotspot.parameters.id);
    const rotation = (hotspot?.parameters && hotspot.parameters["rotation"]) ? hotspot.parameters["rotation"] : 0;

    this._ps.rotation.next(rotation);
    this._ps.currentPhotosphere.next(photosphereData);
    if (hotspot?.gtm_id) {
      GTM.sendGTMEvent(hotspot.gtm_id);
    }

  }

  public setModalById(modalId: string, gtmTitle: string = ""): void {
    if (gtmTitle) {
      GTM.sendNavigationEvent(gtmTitle);
    }
    let modal;
    if (this.state === HeaderState.ShortBowel && modalId !== 'shortBowel') {
      const vids = Videos.find(mod => mod.id === 'shortBowel');
      const data = vids.videos.find(mod => mod.id === modalId);
      modal = {
        active_video: data,
        modal_type: 'video-player',
        id: modalId,
        all_videos: vids.videos,
        parent: 'shortBowel',
        gtm_id: (data.gtm_id) ? data.gtm_id : null
      };
    } else if (this.state === HeaderState.Perianal && modalId !== 'chronsPerianal') {
      const vids = Videos.find(mod => mod.id === 'chronsPerianal');
      const data = vids.videos.find(mod => mod.id === modalId);
      modal = {
        active_video: data,
        modal_type: 'video-player',
        id: modalId,
        all_videos: vids.videos,
        parent: 'chronsPerianal',
        gtm_id: (data.gtm_id) ? data.gtm_id : null
      };

      this._ms.modalData.next(data);
    } else {
      modal = Modals.find(mod => mod.id === modalId);
    }
    // console.log('modal', modal);
    this.setModal(modal);
  }

  public setModal(modal: any): void {
    this._ms.modalData.next(modal);
    this._ms.modalVisible.next(true);
  }

}
