import { Component, Input, OnInit } from '@angular/core';
import * as GTM from "../utils/GTM";

@Component({
  selector: 'app-redirect-to-external-link-modal',
  templateUrl: './redirect-to-external-link-modal.component.html',
  styleUrls: ['./redirect-to-external-link-modal.component.scss'],
})
export class RedirectToExternalLinkModalComponent implements OnInit {
  @Input()
  payload: any;

  private alreadyRedirected: boolean = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.goToLink();
    }, 3000);
  }

  public onLinkClick($event: any) {
    $event.preventDefault();
    this.alreadyRedirected = false;
    this.goToLink();
  }

  private goToLink() {
    if (this.alreadyRedirected) return;

    this.alreadyRedirected = true;
    GTM.sendExternalLinkEvent(this.payload.title, this.payload.href);
    window.open(this.payload.href, '_blank');
  }
}
