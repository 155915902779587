// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const commonEnv = {
  assetBasePath: (!(window.location.host.indexOf('localhost') > -1 || window.location.host.indexOf('stage') > -1)) ? 'https://gma.takvb20.com/final-content/' : 'https://stage-gma.websitemagic.dev/final-content/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
