import { Component, OnInit } from '@angular/core';
import data from '../data/trials.json';
import Modals from '../data/modals.json';
import { StudyService } from '../services/study.service';
import { ModalService } from '../services/modal.service';
import * as GTM from '../utils/GTM';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    public data: any = data;
    public noResults: boolean = false;

    constructor(private _ss: StudyService, private _ms: ModalService) { }

    ngOnInit(): void {
    }

    public handleSearch(): void {
        const condition = document.querySelector('#condition')['value'];
        const treatment = document.querySelector('#treatment')['value'];
        const country = document.querySelector('#country')['value'];

        let results = this.data.study_data;

        // console.log(condition, treatment, country);

        if (condition !== 'All') {
            results = results.filter(el => {
                return el.condition === condition;
            });
        }

        if (treatment !== 'All') {
            results = results.filter(el => {
                return el.treatment === treatment;
            });
        }

        if (country !== 'All') {
            results = results.filter(el => {
                return el.countries.includes(country);
            });
        }

        if (results.length) {
            this.handleResults(results);
        } else {
            this.noResults = true;
        }
    }

    public conditionClick(condition): void {
        condition.id = condition.id.replace('<br>', '');
        const results = this.data.study_data.filter(el => {
            return el.condition === condition.id;
        });
        this.handleResults(results, condition);
        GTM.sendGTMEvent(condition.gtm_id);
    }

    public handleResults(results, condition = ''): void {
        this.noResults = false;

        let treatment = '';
        let active = 0;
        for (const [i, r] of results.entries()) {
            if (treatment === r.treatment && !r.sub_treatment) {
                r.skip = true;
                results[active].rowcount++;
            } else {
                treatment = r.treatment;
                active = i;
                results[active].rowcount = 1;
            }
        }

        this._ss.results.next(results);
        this._ss.condition.next(condition);
        const modalData = {
            modal_type: 'study',
            id: 'clinicalTrials',
            parent: 'clinicalTrials',
        }
        this._ms.modalData.next(modalData);
    }

    public selectChange(e, parent): void {
        this.noResults = false;

        // console.log(e);

        // const num = 18;
        // let newText = e;
        // if (e.length > num) {
        //     newText = e.slice(0, num) + '...';
        // }

        // const select = document.getElementById(parent) as HTMLSelectElement;
        // select.options[select.selectedIndex].innerHTML = newText;
    }
}
