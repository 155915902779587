import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as GTM from '../utils/GTM';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public modalVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public modalData: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public mirfVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public blockEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { 
        window.addEventListener(
            'hiMIRFEvent',
            this.onMedicalFormSubmitted.bind(this)
        );
    }


    public mirf(show: boolean): void {
        if (show) {
            window['medicalForm'].open();
            GTM.sendGTMEvent(26);
        } else {
            window['medicalForm'].close();
        }
        this.mirfVisible.next(show);
    }

    public onMedicalFormSubmitted(e: any) {
        GTM.sendGTMEvent(27);
    }

}
