import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../services/modal.service';
import Modals from '../data/modals.json';
import { HeaderService } from '../services/header.service';
import {FooterService} from '../services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() hideChat: boolean = false;

  public headState: any;
  public small: boolean = true;
  
  public definitionText: string;
  public extendedFooter: boolean = true;

  constructor(private _ms: ModalService, private _hs: HeaderService, private _fs: FooterService) { }

  ngOnInit(): void {
    this.subscribe();
    window.addEventListener("HoudiniSays", function(e :CustomEvent) { 
      if (e.detail === 'modalClose') {
        this._ms.mirfVisible.next(false);
      }
    }.bind(this));
    window.addEventListener("hiTwilioChatEvent", function(e: CustomEvent) {
      if (e.detail.eventData === "show-meeting-request-form") {
        this._ms.mirfVisible.next(true);
      }
      if (e.detail.eventData === "hide-meeting-request-form") {
        this._ms.mirfVisible.next(false);
      }
    }.bind(this))
  }
  subscribe(): void {
    this._hs.headerState.subscribe(data => {
      this.headState = data;
      // enum HeaderState {
      //   Home = 'home-state',
      //   Research = 'research-state',
      //   Ulcer = 'ulcer-state',
      //   ShortBowel = 'short-bowel-state',
      //   Perianal = 'perianal-state'
      // }
      if (this.headState === 'research-state') {
        this.small = false;
      } else {
        this.small = true;
      }
    });

    this._fs.footerUcState.subscribe(data => 
      {
        this.definitionText = data ? "GI: Gastrointestinal<br>UC: Ulcerative Colitis" : "GI: Gastrointestinal";
      })
  }

  public exitClick(): void {
    const data = {
      modal_type: 'exit'
    };

    this._ms.modalVisible.next(true);
    this._ms.modalData.next(data);
  }

  public medicalRequest(): void {
    this._ms.mirf(true);
  }

  public setModalById(modalId: string): void {
    // productInfo
    // GTM.sendGTMEvent(1);
    const modal = Modals.find(mod => mod.id === modalId);
    this._ms.modalData.next(modal);
    this._ms.modalVisible.next(true);

  }
}
