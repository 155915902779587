import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as THREE from 'three';
import { TweenMax } from 'gsap';
// import { gsap } from 'gsap/all';
// import { EaselPlugin, gsap } from 'gsap/all';

import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls.js';
import { Euler } from 'three';
import Hotspots from './../data/hotspotData.json';
import Photospheres from '../data/photospheres.json';

import { ModalService } from '../services/modal.service';
import { PhotosphereService } from '../services/photosphere.service';

import * as GTM from '../utils/GTM';


// const gsapStuff = [gsap];

@Component({
    selector: 'app-photosphere',
    templateUrl: './photosphere.component.html',
    styleUrls: ['./photosphere.component.scss']
})

export class PhotosphereComponent implements OnInit, OnChanges {
    mainMaterial: THREE.MeshBasicMaterial;
    clickMaterial: THREE.MeshBasicMaterial;
    labels: any[] = [];
    updatedSphere = false;
    // Momentum Stuff. 
    private scroll_timeout;
    private linear_tween;
    private momentum_tween;
    private momentumDiff = 0;
    private momentumX = 0;
    private acceleration;
    private startTime;
    private speed;
    private config = {
        momentum: true,
        linear_duration: 0.3, // seconds
        momentum_duration: 1, // seconds
        linear_distance_factor: 4,
        momentum_distance_factor: 10,
        scroll_timeout: 20, // milliseconds
    }

    constructor(private _ps: PhotosphereService, private _ms: ModalService) {
        // gsap.registerPlugin(EaselPlugin);
    }

    @Input() closeCardInput: boolean;
    @Input() actionInput: any;

    @Output() hidePhotosphereOutput = new EventEmitter<any>();

    public staticImage: boolean = false;

    public currentPhotosphere = Photospheres[0];

    public numSpheres: number = 0;
    public numLoadedSpheres: number = 0;

    public showCover = false;
    public showPhotosphere = false;
    public modalVisible: boolean = false;
    public photoSphereInteractive: boolean = false;
    public interactive: boolean = false;
    public motionControls = false;
    public motionControlsPossible = false;
    public motionControlsPermitted = false;

    public loadedSphere = false;
    public loadedTransition = false;
    public initialized = false;

    public camera: THREE.PerspectiveCamera;
    public controls;
    public scene: THREE.Scene;
    public pickingScene: THREE.Scene;
    public renderer: THREE.WebGLRenderer;
    public labelRenderer: CSS2DRenderer;
    public cameraTarget: THREE.Vector3;
    public plane: CSS2DObject;
    public olMaterial: THREE.MeshBasicMaterial;
    public pickHelper: GPUPickHelper;
    public mainTexture: THREE.Texture;

    public isUserInteracting = false;
    public onMouseDownMouseX = 0;
    public onMouseDownMouseY = 0;
    public lonVal = 135;
    get lon(): number {
        return this.lonVal;
    };
    set lon(x) {
        if (this.currentPhotosphere.rotationParams?.limitX) {
            this.lonVal = Math.min(Math.max(x,
                this.currentPhotosphere.rotationParams?.limitX[0]),
                this.currentPhotosphere.rotationParams?.limitX[1]);
        } else {
            this.lonVal = x;
        }
    };
    public onMouseDownLon = 0;

    public latVal = 0;
    get lat(): number {
        return this.latVal;
    };
    set lat(y) {
        if (this.currentPhotosphere.rotationParams?.limitY) {
            this.latVal = Math.min(Math.max(y,
                this.currentPhotosphere.rotationParams?.limitY[0]),
                this.currentPhotosphere.rotationParams?.limitY[1]);
        } else {
            this.latVal = y;
        }
    };
    public onMouseDownLat = 0;
    public phi = 0;
    public theta = 0;
    public hasMoved = false;
    public targetRotation = 0;
    @Output() analytics = new EventEmitter<any>();

    ngOnInit(): void {
        this.subscribe();
        this.preloadSpheres();
    }

    subscribe(): void {
        this._ps.rotation.subscribe(data => {
            this.targetRotation = data;
        });

        this._ps.interactable.subscribe(data => {
            if (this.interactive) {
                this.photoSphereInteractive = data;
            } else {
                this.photoSphereInteractive = false;
            }
        });

        this._ps.currentPhotosphere.subscribe(data => {
            if (!this.updatedSphere) {
                this.init();
                this.animate();
                this.updatedSphere = true;
                // console.log('ONE', this.currentPhotosphere);
            }

            this.currentPhotosphere = data;
            this.loadNewPhotosphere();
            // console.log('TWO', this.currentPhotosphere);
        });

        this._ms.modalVisible.subscribe(data => {
            this.modalVisible = data;
        });

        this._ms.modalData.subscribe((data) => {
            if (data && data?.id !== "about-takeda") {
                this.hasMoved = true;
            }
        });
    }

    ngOnDestroy(): void {
        document.removeEventListener('mousedown', this.onPointerStart);
        document.removeEventListener('mousemove', this.onPointerMove);
        document.removeEventListener('mouseup', this.onPointerUp);
        document.removeEventListener('wheel', this.onDocumentMouseWheel);
        document.removeEventListener('touchstart', this.onPointerStart);
        document.removeEventListener('touchmove', this.onPointerMove);
        document.removeEventListener('touchend', this.onPointerUp);
        window.removeEventListener('resize', this.onWindowResize);
    }

    ngOnChanges(): void {
        this.showCover = true;
        this.showPhotosphere = false;
        this.loadedSphere = false;
        this.loadedTransition = false;
    }

    public preloadSpheres(): void {
        // this.numSpheres = Photospheres.length * 2;
        // console.log('We are loading Spheres!!');
        // console.log(Photospheres, "are the photospheres");

        Photospheres.forEach(p => {
            // Tony Conti: Add logic around here incase we dont have a transparent layer.
            this.numSpheres++;
            p.mainImageBmp = new THREE.TextureLoader().load('assets/photospheres/' + p.mainImage,
                this.sphereLoaded.bind(this) // On Load Event
            );
            if (p.transparencyImage) {
                this.numSpheres++;
                p.transparencyImageBmp = new THREE.TextureLoader().load('assets/photospheres/' + p.transparencyImage,
                    this.sphereLoaded.bind(this) // On Load Event
                );
            }
        });
    }
    public sphereLoaded(): void {
        // console.log('sphereLoaded', this.numLoadedSpheres);

        this.numLoadedSpheres++;
        if (this.numLoadedSpheres >= this.numSpheres) {
            this._ps.photosphereLoaded.next(true);
        }
    }

    public loadNewPhotosphere(): void {
        // The spheres are loaded... 
        setTimeout(() => {
            this.showCover = true;
            // TODO add in 3d stuff and load, once ready then showCover = false and
            setTimeout(() => {
                this.loadedTransition = true;

                this.setTextures();
                if (this.loadedSphere) {
                    this.sphereTextureLoaded(null);
                }
            }, 1);
        }, 1);
    }

    public sphereTextureLoaded(texture): void {
        // The main Sphere texture is loaded.
        this.loadedSphere = true;
        // Did the texture finish before our little loading delay?
        if (this.loadedTransition) {
            this.showCover = false;
            this.showPhotosphere = true;
            this.initLabels();

            // Tony Conti: Scene enter animation.
            if (!this.motionControls && this.targetRotation) {
                const destin = this.targetRotation;
                const fromVal = 180;
                TweenMax.fromTo(this, 1.5, { lon: fromVal }, { lon: destin, delay: .3, ease: 'expo.out' });
                // Different FOV zoom for smaller screens
                const fov = window.innerWidth < 1440 ? 73 : 57.5;
                TweenMax.fromTo(this.camera, 1.5, { fov: 90 }, { fov: fov, onUpdate: this.onCameraUpdate.bind(this), onComplete: this.updateFlags.bind(this)});
            }
        }
    }
    public onCameraUpdate(): void {
        this.camera.updateProjectionMatrix();
    }

    public hidePhotosphere(event: Event): void {
        event.stopPropagation();
        this.showCover = true;

        setTimeout(() => {
            this.hidePhotosphereOutput.emit(true);
            this.showPhotosphere = false;
            this.loadedSphere = false;
        }, 500);
    }

    public consumeMouseUp(event: Event): void {
        event.stopPropagation();
    }

    // THREE JS Methods

    public init(): void {
        let container: HTMLElement;
        container = document.getElementById('container');
        if (this.staticImage) {

            this.scene = new THREE.Scene();
            this.pickingScene = new THREE.Scene();
            this.cameraTarget = new THREE.Vector3(0, 0, 0);

            /**
             * Camera
             */

            // Specify the portion of the scene visiable at any time (in degrees)
            const fieldOfView = 75;

            // Specify the camera's aspect ratio
            const aspectRatio = window.innerWidth / window.innerHeight;

            // Specify the near and far clipping planes. Only objects
            // between those planes will be rendered in the scene
            // (these values help control the number of items rendered
            // at any given time)
            const nearPlane = 0.1;
            const farPlane = 1000;

            // Use the values specified above to create a camera
            this.camera = new THREE.PerspectiveCamera(
                fieldOfView, aspectRatio, nearPlane, farPlane
            );

            // Finally, set the camera's position in the z-dimension
            this.camera.position.z = 5;

            /**
             * Renderer
             */

            // Create the canvas with a renderer
            this.renderer = new THREE.WebGLRenderer({antialias: true});

            // Specify the size of the canvas
            this.renderer.setSize( window.innerWidth, window.innerHeight );

            // Add the canvas to the DOM
            container.appendChild( this.renderer.domElement );

            /**
            * Image
            **/

            // Create a texture loader so we can load our image file
            const loader = new THREE.TextureLoader();

            // Load an image file into a custom material
            const material = new THREE.MeshLambertMaterial({
                map: this.currentPhotosphere.mainImageBmp
                //map: loader.load('https://s3.amazonaws.com/duhaime/blog/tsne-webgl/assets/cat.jpg')
            });

            // create a plane geometry for the image with a width of 10
            // and a height that preserves the image's aspect ratio
            const geometry = new THREE.PlaneGeometry(10, 10*.75);

            // combine our image geometry and material into a mesh
            const mesh = new THREE.Mesh(geometry, material);

            // set the position of the image mesh in the x,y,z dimensions
            mesh.position.set(0,0,0)

            // add the image to the scene
            this.scene.add(mesh);


            this.olMaterial = new THREE.MeshBasicMaterial({transparent: true});
            this.mainTexture = this.currentPhotosphere.mainImageBmp;
            this.sphereTextureLoaded(null);
            this.mainMaterial = new THREE.MeshBasicMaterial({ map: this.mainTexture });
            this.mainMaterial.needsUpdate = true;

        } else {
            this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 1100);
            this.cameraTarget = new THREE.Vector3(0, 0, 0);
    
            this.scene = new THREE.Scene();
            this.pickingScene = new THREE.Scene();
    
            this.resetAssets();
    
                
            this.renderer = new THREE.WebGLRenderer();
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.pickHelper = new GPUPickHelper(this.renderer);
            container.appendChild(this.renderer.domElement);
        }

        // if (this.currentPhotosphere.clickableImage) {
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        container.appendChild(this.labelRenderer.domElement);
        // }

        document.addEventListener('mousedown', this.onPointerStart.bind(this), false);
        document.addEventListener('mousemove', this.onPointerMove.bind(this), false);
        document.addEventListener('mouseup', this.onPointerUp.bind(this), false);

        document.addEventListener('wheel', this.onDocumentMouseWheel.bind(this), false);

        document.addEventListener('touchstart', this.onPointerStart.bind(this), false);
        document.addEventListener('touchmove', this.onPointerMove.bind(this), false);
        document.addEventListener('touchend', this.onPointerUp.bind(this), false);

        window.addEventListener('resize', this.onWindowResize.bind(this), false);
        // window.addEventListener('deviceorientation', this.handleOrientation.bind(this), true);
        if (typeof (DeviceMotionEvent) !== 'undefined' && typeof DeviceMotionEvent.requestPermission === 'function') {
            this.motionControlsPossible = true;
            this.promptMotionControls();
        } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            this.motionControlsPossible = true;
            this.permitMotionControls();

        } else {
            // handle regular non iOS 13+ devices
        }
    }

    public resetAssets(): void {
        let mesh: THREE.Object3D;
        let olMesh: THREE.Object3D;
        let clickMesh: THREE.Object3D;

        const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry.scale(-1, 1, 1);

        //const geometry = new THREE.PlaneGeometry(10, 10*.75);

        // this.mainTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.mainImage}`,
        this.mainTexture = this.currentPhotosphere.mainImageBmp;
        this.sphereTextureLoaded(null);
        // );
        this.mainMaterial = new THREE.MeshBasicMaterial({ map: this.mainTexture });
        this.mainMaterial.needsUpdate = true;

        mesh = new THREE.Mesh(geometry, this.mainMaterial);
        //mesh.position.set(0,0,0)

        if (this.currentPhotosphere.transparencyImage) {

            const olGeometry = new THREE.SphereBufferGeometry(400, 60, 40);
            // invert the geometry on the x-axis so that all of the faces point inward
            olGeometry.scale(-1, 1, 1);

            // const olTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.transparencyImage}`);
            const olTexture = this.currentPhotosphere.transparencyImageBmp;
            this.olMaterial = new THREE.MeshBasicMaterial({ map: olTexture, transparent: true });
            this.olMaterial.needsUpdate = true;

            olMesh = new THREE.Mesh(olGeometry, this.olMaterial);
            this.scene.add(olMesh);

        }
        else {
            this.olMaterial = new THREE.MeshBasicMaterial({transparent: true});
        }


        // if (this.currentPhotosphere.clickableImage) {

        //     const clickGeometry = new THREE.SphereBufferGeometry(300, 60, 40);
        //     // invert the geometry on the x-axis so that all of the faces point inward
        //     clickGeometry.scale(- 1, 1, 1);

        //     const clickTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.clickableImage}`);
        //     this.clickMaterial = new THREE.MeshBasicMaterial({ map: clickTexture });

        //     clickMesh = new THREE.Mesh(clickGeometry, this.clickMaterial);
        //     this.pickingScene.add(clickMesh);

        // }

        this.scene.add(mesh);
        this.hasMoved = false;
    }

    setTextures() {
        // this.mainTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.mainImage}`,
        //     this.sphereTextureLoaded.bind(this) // On Load Event
        // );
        // console.log('SET TEXTURES: this.currentPhotosphere.mainImageBmp', this.currentPhotosphere.mainImageBmp);
        this.mainTexture = this.currentPhotosphere.mainImageBmp;
        this.mainMaterial.map = this.mainTexture;
        // this.mainTexture.image.onload = () => { this.mainTexture.needsUpdate = true; };
        this.mainTexture.needsUpdate = true;


        // this.sphereTextureLoaded(null);


        if (this.currentPhotosphere.transparencyImage != null) {
            if (!this.olMaterial) {
                let olMesh: THREE.Object3D;

                const olGeometry = new THREE.SphereBufferGeometry(400, 60, 40);
                // invert the geometry on the x-axis so that all of the faces point inward
                olGeometry.scale(-1, 1, 1);

                // const olTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.transparencyImage}`);
                const olTexture = this.currentPhotosphere.transparencyImageBmp;
                this.olMaterial = new THREE.MeshBasicMaterial({ map: olTexture, transparent: true });
                this.olMaterial.needsUpdate = true;

                olMesh = new THREE.Mesh(olGeometry, this.olMaterial);
                this.scene.add(olMesh);
            }
            // const olTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.transparencyImage}`);
            this.olMaterial.map = this.currentPhotosphere.transparencyImageBmp;

            this.olMaterial.opacity = 1;
            this.olMaterial.needsUpdate = true;
            // this.mainTexture = this.currentPhotosphere.transparencyImageBmp;

        }
        else {
            this.olMaterial.opacity = 0;
            this.olMaterial.transparent = true;
        }


        // if (this.currentPhotosphere.clickableImage) {
        //     const clickTexture = new THREE.TextureLoader().load(`assets/photospheres/${this.currentPhotosphere.clickableImage}`);
        //     this.clickMaterial.map = clickTexture;
        // }
        this.lon = this.currentPhotosphere.rotationParams.startX;
        this.lat = this.currentPhotosphere.rotationParams.startY ?? 0;
        this.loadedSphere = true;
    }

    public promptMotionControls() {
        DeviceMotionEvent.requestPermission()
            .then(permissionState => {
                if (permissionState === 'granted') {
                    // window.addEventListener('devicemotion', this.handleMotion.bind(this), true);
                    this.permitMotionControls();
                }
            })
            .catch(console.error);
    }

    public permitMotionControls() {
        window.addEventListener('deviceorientation', this.handleOrientation.bind(this), true);
        this.motionControls = true;
        this.motionControlsPermitted = true;
        this.controls = new DeviceOrientationControls(this.camera);
    }

    public toggleMotionControls() {
        if (!this.motionControlsPossible) { return; }
        if (this.motionControlsPermitted) {
            this.motionControls = !this.motionControls;
        } else {
            this.promptMotionControls();
        }
    }

    public handleMotion(event: any): void {
        this.lon += event.acceleration.x;
        this.lat += event.acceleration.y;
    }

    public handleOrientation(event: any): void {
        if (this.motionControls) {
            const gamma = event.gamma;
            const beta = event.beta;
            const alpha = event.alpha;
            const euler = new Euler();
            euler.set(beta, alpha, -gamma, 'YXZ');
            this.lat = -90 + euler.x + this.currentPhotosphere.rotationParams.startX;
            this.lon = -90 - euler.y;
        }
    }

    public initLabels(): void {
        this.labels.forEach(label => {
            this.scene.remove(label);
            label.remove();
        });
        let i = 1;
        const d = .05;
        this.currentPhotosphere.hotspots.forEach(element => {
            const label = this.makeLabelObject(element, element.x * this.currentPhotosphere.mainScale, element.y * this.currentPhotosphere.mainScale);
            this.scene.add(label);
            this.labels.push(label);
            const toY = label.position.y;
            // TweenMax.fromTo(label.position, .5, { y: (label.position.y - 30) }, { y: toY, delay: .2 + (i * d) });

            i++;
        });

    }

    public getPositionFromEquirectangularSpace(x, y): THREE.Vector3 {
        const xfrac = x / this.mainTexture.image?.width;
        const yfrac = y / this.mainTexture.image?.height;
        const xrot = 2 * Math.PI * xfrac;
        const yrot = (Math.PI) * (yfrac - .5);
        const forward = new THREE.Vector3(50, 0, 0);
        forward.applyAxisAngle(new THREE.Vector3(0, 0, -1), yrot);
        forward.applyAxisAngle(new THREE.Vector3(0, -1, 0), xrot);
        return forward;
    }

    public makeLabelObject(hotspot, x, y): CSS2DObject {
        const text = document.createElement('div');
        //text.className = 'photosphere-flag pflag pflag-' + hotspot?.id;
        text.className = 'photosphere-flag';
        text.appendChild(this.createSvgPlus());
        const card = Hotspots.find(hs => hs.id === hotspot.dataId);
        // text.textContent = card.label;
        text.onclick = () => {
            this.hasMoved = true;
            this.actionInput[card.action](card.parameters);
            if (card.gtm_id > 0) {
                this._ms.blockEvent.next(true);
                GTM.sendGTMEvent(card.gtm_id);
            } else {
                GTM.sendHotspotEvent(card.label);
            }
        };
        if (card["hoverText"]) {
            text.appendChild(this.createHoverText(card["hoverText"], card["id"]));
        }
        const label = new CSS2DObject(text);
        const forward = this.getPositionFromEquirectangularSpace(x, y);
        label.position.x = forward.x;
        label.position.y = forward.y;
        label.position.z = forward.z;
        // console.log(label, "New Label;");
        return label;
    }

    public createSvgPlus(): any {
        // <svg xmlns="http://www.w3.org/2000/svg" width="23.131" height="23.626" viewBox="0 0 23.131 23.626">
        //  <path id="Path_768" data-name="Path 768" d="M3617.427,347.643h-10.774V346.16h10.774V335.089h1.582V346.16h10.775v1.483h-10.775v11.072h-1.582Z" transform="translate(-3606.653 -335.089)" fill="#fff"/>
        // </svg>

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('width', '23.131');
        svg.setAttribute('height', '23.626');
        svg.setAttribute('ViewBox', '0 0 23.131 23.626');

        const newElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        newElement.setAttribute('d', 'M3617.427,347.643h-10.774V346.16h10.774V335.089h1.582V346.16h10.775v1.483h-10.775v11.072h-1.582Z');
        newElement.setAttribute('fill', '#FFF');
        newElement.setAttribute('transform', 'translate(-3606.653 -335.089)');
        svg.appendChild(newElement);
        return svg;
    }

    public createHoverText(lines: string[], id: any): any {
        const parser = new DOMParser();
        const bubble = document.createElement('div');
        bubble.classList.add('hotspot-bubble');
        // Add hotspot id as a CSS class, so we can target additional styles
        bubble.classList.add('' + id);
        let htmlString = '';
        for (const line of lines) {
            htmlString += line;
        }
        bubble.innerHTML = htmlString;
        return bubble;
    }

    public createElementFromHTML(htmlString): Node {
        const div = document.createElement('div');
        div.innerHTML = htmlString;
        // console.log(div);
        return div.firstChild;
    }

    public onWindowResize(): void {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
    }

    public onPointerStart(event): void {
        // console.log('this.photoSphereInteractive', this.photoSphereInteractive);

        if (!this.photoSphereInteractive || this.modalVisible) {
            return;
        }
        this.isUserInteracting = true;

        const clientX = event.clientX || event.touches[0].clientX;
        const clientY = event.clientY || event.touches[0].clientY;

        this.onMouseDownMouseX = clientX;
        this.onMouseDownMouseY = clientY;

        this.onMouseDownLon = this.lon;
        this.onMouseDownLat = this.lat;
        this.momentumDiff = 0;
        this.momentumX = clientX;
        this.startTime = new Date();
        if (this.momentum_tween) {
            this.momentum_tween.kill();
        }

    }

    public onPointerMove(event): void {
        if (!this.photoSphereInteractive || this.modalVisible) {
            return;
        }
        if (this.isUserInteracting === true) {

            const clientX = event.clientX || event.touches[0].clientX;
            const clientY = event.clientY || event.touches[0].clientY;

            this.lon = (this.onMouseDownMouseX - clientX) * 0.1 + this.onMouseDownLon;
            // console.log(this.lon, "is the lon");
            this.lat = (clientY - this.onMouseDownMouseY) * 0.1 + this.onMouseDownLat;
            this.hasMoved = true;

            if (this.scroll_timeout) {
                clearTimeout(this.scroll_timeout);
            }
            // console.log('this.lon', this.lon, (this.onMouseDownMouseX - clientX) * .1, this.onMouseDownMouseX, clientX, this.onMouseDownLon, this.momentumDiff)
            // this.tweenLinearMomentum(this.onMouseDownMouseX - clientX);

            // when no new scroll event fires, tween momentum effect
            if (this.config.momentum) {
                this.scroll_timeout = setTimeout(() => {
                    this.momentumDiff = (this.momentumX - clientX) * .1;
                    // this.tweenMomentum(this.onMouseDownLon - this.lon);
                    if (!this.isUserInteracting) {
                        const now: any = new Date();
                        const time: any = (now - this.startTime) * .02;
                        this.speed = this.momentumDiff / (time);
                        this.acceleration = this.speed / (time);
                        // console.log('this.speed', this.speed, this.acceleration);

                        this.tweenMomentum(this.acceleration);
                    } else {
                        // this.momentumX = clientX;
                    }
                }, this.config.scroll_timeout);
            }

        }
    }

    public updateFlags():void {
        this.labels.forEach(label => {
            this.camera.matrixWorldInverse.getInverse( this.camera.matrixWorld ); // may already be computed
            var mat = this.camera.matrixWorldInverse.multiply(  label.matrixWorld );
            var pos = label.position.clone().applyMatrix4(mat);
            // console.log(pos);
            const isLeft = pos.x > 0;
            label.element.classList.toggle("left", isLeft);
        });
    }

    public onPointerUp(event): void {
        this.isUserInteracting = false;
        this.updateFlags();

        // const clientX = event.clientX || event.touches[0].clientX;
        // const clientY = event.clientY || event.touches[0].clientY;
        // if (this.onMouseDownMouseX === clientX && this.onMouseDownMouseY === clientY) {
        //     const id = this.pickHelper.pick({ x: clientX, y: clientY }, this.pickingScene, this.camera);
        //     if (id > 0) {
        //         const card = this.currentPhotosphere.hotspots.find(el => {
        //             // tslint:disable-next-line: radix
        //             return parseInt(el.color) === id;
        //         });
        //         // this.testmodalVisible(true, card);
        //     }
        // }

    }


    public tweenLinearMomentum(x: number): void {
        if (this.momentum_tween) {
            this.momentum_tween.kill();
        }
        // TweenMax.fromTo(this, 1.5, { lon: fromVal }, { lon: destin, delay: .3, ease: 'expo.out' });
        this.linear_tween = TweenMax.to(this, this.config.linear_duration, {
            lon: `+=${this.config.linear_distance_factor * x}`,
            ease: Power0.easeNone
        });
    }

    public tweenMomentum(x: number): void {
        if (this.linear_tween) {
            this.linear_tween.kill();
        }
        this.momentum_tween = TweenMax.to(this, this.config.momentum_duration, {
            lon: `+=${this.config.momentum_distance_factor * x}`,
            ease: Power2.easeOut
        });
        // console.log('tweenMomentum', `+=${this.config.momentum_distance_factor * x}`);
    }

    hexToBin(hex: any): number {
        const r = hex >> 16;
        const g = hex >> 8 & 0xFF;
        const b = hex & 0xFF;
        const id =
            (r << 16) |
            (g << 8) |
            (b << 0);

        return id;
    }

    public onDocumentMouseWheel(event): void {
        // event.preventDefault();
        if (!this.photoSphereInteractive || this.modalVisible || this._ms.mirfVisible.value) {
            return;
        }
        const fov = this.camera.fov + event.deltaY * 0.05;

        this.camera.fov = THREE.MathUtils.clamp(fov, 30, 70);

        this.camera.updateProjectionMatrix();
    }

    public animate(): void {
        requestAnimationFrame(this.animate.bind(this));
        this.update();
    }

    public update(): void {
        if (this.showPhotosphere) {
            if (this.motionControls) {
                this.controls.update();

                const cameraVector = new THREE.Vector3();
                this.camera.getWorldDirection(cameraVector);
                cameraVector.y = 0;
                const zeroVector = new THREE.Vector3(1, 0, 0);
                let angle = zeroVector.angleTo(cameraVector) * 180 / Math.PI;
                if (cameraVector.z < 0) {
                    angle = 360 - angle;
                }
                this._ps.currentRotation.next(angle);
            } else {

                this.lat = Math.max(- 85, Math.min(85, this.lat));
                this.phi = THREE.MathUtils.degToRad(90 - this.lat);
                this.theta = THREE.MathUtils.degToRad(this.lon);

                this.cameraTarget.x = 500 * Math.sin(this.phi) * Math.cos(this.theta);
                this.cameraTarget.y = 500 * Math.cos(this.phi);
                this.cameraTarget.z = 500 * Math.sin(this.phi) * Math.sin(this.theta);

                this.camera.lookAt(this.cameraTarget);
                const cameraVector = new THREE.Vector3(this.cameraTarget.x, 0, this.cameraTarget.z);
                const zeroVector = new THREE.Vector3(1, 0, 0);
                let angle = zeroVector.angleTo(cameraVector) * 180 / Math.PI;
                if (cameraVector.z < 0) {
                    angle = 360 - angle;
                }
                this._ps.currentRotation.next(angle);

                // if (this.currentPhotosphere.transparencyImage) {
                //     this.olMaterial.opacity = (Math.sin(Date.now() / 640) + 1) / 2;
                // }

            }
            this.renderer.render(this.scene, this.camera);
            if (this.labelRenderer) {
                this.labelRenderer.render(this.scene, this.camera);
            }
        }
    }

}

class GPUPickHelper {
    public pickingTexture: THREE.WebGLRenderTarget;
    public pixelBuffer: Uint8Array;
    public renderer: THREE.WebGLRenderer;

    constructor(r: THREE.WebGLRenderer) {
        // create a 1x1 pixel render target
        this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
        this.pixelBuffer = new Uint8Array(4);
        this.renderer = r;
    }
    pick(cssPosition, scene, camera) {
        const { pickingTexture, pixelBuffer } = this;

        // set the view offset to represent just a single pixel under the mouse
        const pixelRatio = this.renderer.getPixelRatio();
        camera.setViewOffset(
            this.renderer.getContext().drawingBufferWidth,   // full width
            this.renderer.getContext().drawingBufferHeight,  // full top
            cssPosition.x * pixelRatio | 0,             // rect x
            cssPosition.y * pixelRatio | 0,             // rect y
            1,                                          // rect width
            1,                                          // rect height
        );
        // render the scene
        this.renderer.setRenderTarget(pickingTexture);
        this.renderer.render(scene, camera);
        this.renderer.setRenderTarget(null);
        // clear the view offset so rendering returns to normal
        camera.clearViewOffset();
        // read the pixel
        this.renderer.readRenderTargetPixels(
            pickingTexture,
            0,   // x
            0,   // y
            1,   // width
            1,   // height
            pixelBuffer);

        const id =
            (pixelBuffer[0] << 16) |
            (pixelBuffer[1] << 8) |
            (pixelBuffer[2] << 0);
        if (pixelBuffer[3] == 0) {
            return 0;
        }

        return id;
    }
}
