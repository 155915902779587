import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StudyService {
    public results: BehaviorSubject<any> = new BehaviorSubject(undefined);
    public condition: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() { }
}
