<div class="trial-modal">
    <div class="header">
        <h2 [innerHTML]="condition.id"></h2>
    </div>

    <div class="results-container">
        <div class="results-header">
            <div class="col col-treatment">Treatment</div>
            <div class="col col-study">Study</div>
            <div class="col col-country">Country</div>
        </div>

        <table class="results">
            <tr *ngFor="let result of results; let i = index">
                <td *ngIf="!result.skip" [attr.rowspan]="result.rowcount ? result.rowcount : 1"
                    class="col col-treatment">
                    <p [innerHTML]="result.treatment"></p>
                    <span *ngIf="result.sub_treatment" [innerHTML]="result.sub_treatment"></span>
                </td>
                <td class="col col-study">
                    <div class="pdf-link">
                        <p [innerHTML]="result.study" (click)="openStudy(result)"></p>
                        <sup *ngIf="result.footnote">{{result.footnote}}</sup>
                    </div>
                </td>
                <td class="col col-country">
                    <p *ngIf="result.countries_text" [innerHTML]="result.countries_text"></p>

                    <ul>
                        <li *ngFor="let c of result.countries" [innerHTML]="c"></li>
                    </ul>
                </td>
            </tr>
        </table>

        <div class="results-footer cf">
            <p>
                <span *ngIf="footnote_a"><sup>a</sup>Observational study &nbsp;&nbsp;</span>
                <span *ngIf="footnote_b"><sup *ngIf="footnote_b">b</sup>With a cap on the total number of sites
                    selected</span>
            </p>
            <p class="right">VV-MEDMAT-22100 Date of Preparation: September 2020</p>
        </div>
    </div>
</div>