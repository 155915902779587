<div *ngIf="desktop && magicKey">
  <app-header [ngClass]="{'on': !showWelcome}"></app-header>
  <app-welcome class="app-welcome" [ngClass]="{'on': showWelcome}" (closeWelcomeOutput)="disableWelcomeView()">
  </app-welcome>
  <app-photosphere [actionInput]="Actions" (hidePhotosphereOutput)="handlehidePhotosphere($event)"></app-photosphere>
  <app-modal></app-modal>
  <app-footer [hideChat]="showWelcome"></app-footer>
</div>

<div *ngIf="!desktop && magicKey">
  <div>
    <div class="shadowbox">
      <div class="hi-modal">
        <div class="screensize-content">
          <img class="welcome-logo" src="/assets/takeda_logo.png">
          <h2>Please view on a larger display.</h2>
          <!-- <p class="healthcare-intent">Please view on a larger display.</p> -->
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!magicKey">
  <div>
    <div class="shadowbox">
      <div class="hi-modal">
        <div class="screensize-content">
          <img class="welcome-logo" src="/assets/takeda_logo.png">
          <h2>Permission Denied</h2>

          <p class="healthcare-intent">You need to access this site via UEGW.</p>
        </div>

      </div>

    </div>
  </div>
</div>