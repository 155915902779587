<div class="video-player" [ngClass]="[singleVideo ? 'single' : '']">
    <div class="video-container">
        <video id="video-player" (contextmenu)="$event.preventDefault()" [src]="path + activeVideo?.path" [poster]="path + activeVideo?.poster" autoplay controls controlsList="nodownload"
        disablePictureInPicture preload="auto">
            <source [src]="path + activeVideo?.path" type="video/mp4">
        </video>

        <div *ngIf="!videoPlaying" class="play-btn xl" (click)="playVideo()"></div>
    </div>

    <div class="footer">
        <!-- <h2 class="title" [innerHTML]="activeVideo?.title"></h2> -->

        <div class="right" *ngIf="nextVideo">
            <div class="text">
                <p [innerHTML]="nextVideo.title"></p>
            </div>

            <div class="up-next pill-btn" (click)="nextVideoClick()">
                <span>Up Next</span>
                <div class="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.872" height="22.047" viewBox="0 0 16.872 22.047">
                        <path d="M3246.694,958.889v-.031l-16.872,11.023,16.872,11.023v-.031"
                            transform="translate(3246.694 980.905) rotate(180)" /></svg>
                </div>
            </div>
        </div>
    </div>
</div>
