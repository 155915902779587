import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { environment } from 'src/environments/environment';
import * as GTM from '../utils/GTM';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges {
    @Input() data: any;

    public activeVideo: any = undefined;
    public nextVideo: any = undefined;
    public path: string = environment.assetBasePath + 'videos/';

    public singleVideo: boolean = false;
    public activeIndex: number = 0;
    public videoPlaying: boolean = false;

    constructor(private _ms: ModalService) { }

    public ngOnInit(): void {
        document.querySelector('#video-player').addEventListener('play', this.playHandler.bind(this));
        document.querySelector('#video-player').addEventListener('ended', this.endedHandler.bind(this));
        document.querySelector('#video-player').addEventListener('pause', this.pauseHandler.bind(this));
    }

    public ngOnChanges(): void {
        if (this.data.active_video) {
            this.singleVideo = false;
            this.activeVideo = this.data.active_video;
            const videos = this.data.all_videos;
            this.activeIndex = Object.values(videos).findIndex(x => x['title'] === this.activeVideo.title);
            this.setWatchNext();

            if ((this.activeIndex) !== videos.length) {
                this.nextVideo = videos[this.activeIndex];
            } else {
                this.nextVideo = undefined;
            }
        } else {
            this.singleVideo = true;
            this.activeVideo = this.data;
            this.nextVideo = undefined;
        }
    }

    public playVideo(): void {
        (document.querySelector('#video-player') as HTMLVideoElement).play();
    }

    public playHandler(): void {
        this.videoPlaying = true;
    }

    public pauseHandler(): void {
        this.videoPlaying = false;
    }

    public endedHandler(): void {
        if (!this.singleVideo) {
            this.setWatchNext();
        }
    }

    public nextVideoClick(): void {
        // This is not needed anymore.
        // this.setWatchNext();

        const data = {
            active_video: this.data.all_videos[this.activeIndex],
            id: this.data.all_videos[this.activeIndex].id,
            all_videos: this.data.all_videos,
            modal_type: 'video-player',
            parent: this.data.parent
        };

        // console.log(data, "is the nextVideoClick data");

        this._ms.modalData.next(data);

        if (data.active_video) {
            GTM.sendGTMEvent(data.active_video.gtm_id);
        }
    }

    public setWatchNext(): void {
        this.activeIndex++;
        if (this.activeIndex === this.data.all_videos.length) {
            this.activeIndex = 0;
        }

        for (const i of this.data.all_videos) {
            i.watchNext = false;
        }

        this.data.all_videos[this.activeIndex].watchNext = true;
    }
}
