<div class="shadowbox">
    <div class="hi-modal" [ngClass]="{'redirect': showRedirect}">
        <div class="welcome-content" [ngClass]="{'on': !showRedirect}">
            <div class="inner">
                <img class="welcome-logo" src="/assets/takeda_logo.png">
                <h2>
                    Welcome to the Takeda <br>
                    Medical Affairs Virtual Experience
                </h2>

                <div class="healthcare-prompt" [ngClass]="{'on': showHealthcare}">
                    <p>Are You A Healthcare <br>Professional?</p>
                    <a href="#" class="hi-modal-button" (click)="setCountryPrompt($event)"><span>YES</span></a>
                    <a href="#" class="hi-modal-button" (click)="setRedirect($event)"><span>NO</span></a>
                </div>

                <div class="country-prompt" [ngClass]="{'on': showCountry}">
                    <div class="input-container">
                        <label for="countries">Select Country <br>of Residence</label>

                        <div class="select-container">
                            <select name="countries" class="country-drop-down" (change)="setContinue($event)">
                                <option value="none" selected disabled hidden>Country</option>
                                <option *ngFor="let country of validCountries" [value]="country">{{country}}</option>
                            </select>
                        </div>
                    </div>

                    <br>

                    <a href="#" class="hi-modal-button continue-button" [ngClass]="{'on': showContinue}"
                        (click)="closeWelcome($event)"><span>CONTINUE</span></a>
                </div>

                <p class="healthcare-intent" *ngIf="!showCountry">The information on this website is intended for Healthcare Professionals only.</p>

                <p class="legal">
                    Copyright ©2021 Takeda Pharmaceutical Company Limited. All Rights Reserved.<br>
                    TAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited.<br>
                    All other trademarks appearing herein are the property of their respective owners.
                </p>
                <!-- <a class="privacy" href="https://www.takeda.com/privacy-notice" target="_blank">Privacy Notice</a> -->
                <span class="privacy">VV-MEDMAT-48828 08/2021</span>
            </div>
        </div>

        <div class="welcome-redirect" [ngClass]="{'on': showRedirect && !showRedirect2}">
            <h2>IMPORTANT NOTICE</h2>
            <p class="redirect-text">The information is intended for Healthcare Professionals.<br> As a non-Healthcare Professional, you will be redirected
                to <a href="https://www.takeda.com" (click)="openLink($event)">www.takeda.com</a> for more information about Takeda.</p>
        </div>

        <div class="welcome-redirect welcome-redirect2" [ngClass]="{'on': showRedirect2}">
            <p class="redirect-text">You are now being redirected to <a href="https://www.takeda.com" (click)="openLink($event)">www.takeda.com</a></p>
        </div>

    </div>
</div>

<div class="cover"></div>