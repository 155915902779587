import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';

import { ViewContainerHostDirective } from '../directive/view-container-host.directive';
import { DYNAMIC_COMPONENTS } from '../dynamic-components-config';

@Component({
  selector: 'app-dynamic-components-loader',
  templateUrl: './dynamic-components-loader.component.html',
  styleUrls: ['./dynamic-components-loader.component.scss'],
})
export class DynamicComponentsLoaderComponent implements AfterContentInit {
  @Input()
  modalData: any;

  @ViewChild(ViewContainerHostDirective, { static: true })
  viewContainerHost: ViewContainerHostDirective;

  private factoryResolver;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  ngAfterContentInit(): void {

    // console.log(this.modalData, "is the modalDAta for dynamic component");

    const viewContainerRef = this.viewContainerHost.viewContainerRef;

    const factory = this.factoryResolver.resolveComponentFactory(
      DYNAMIC_COMPONENTS[this.modalData.component]
    );
    const component = factory.create(viewContainerRef.parentInjector);

    if (this.modalData.payload) {
      component.instance.payload = this.modalData.payload;
    }

    if (this.modalData.id) {
      component.instance.id = this.modalData.id;
    }

    viewContainerRef.insert(component.hostView);
  }
}
