<div class="header">
    <img src="/assets/takeda_logo.svg" class="header-logo" />
    <a href="#" *ngIf="showHomeButton" (click)="goHome()" class="home-icon holder"><img src="/assets/home_icon.svg" class="home-icon" /></a>
    <div *ngIf="useCustomHeader" class="pes-state header-state" [ngClass]="{'on': true }">
        <div class="header-item small" *ngIf="state == 'home-state'" [ngClass]="{'on': isModalOpen && modal == 'neurobiology-epilepsy'}"
            (click)="setModalById('neurobiology-epilepsy', 'NEUROBIOLOGY OF EPILEPSY')">
            <span>NEUROBIOLOGY <br>OF EPILEPSY</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'about-dees'}"
            (click)="setModalById('about-dees', 'ABOUT DEEs')">
            <span>ABOUT DEEs</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'symposia'}"
            (click)="setModalById('about-ds-lgs', 'ABOUT DS AND LGS')">
            <span>ABOUT DS <br>AND LGS</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'test-your-knowledge'}"
            (click)="setModalById('test-your-knowledge', 'TEST YOUR KNOWLEDGE')">
            <span>TEST YOUR <br>KNOWLEDGE</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'medinfo-resources'}"
            (click)="setModalById('medinfo-resources', 'MEDINFO RESOURCES')">
            <span>MEDINFO &amp;<br>RESOURCES</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="pes-state header-state" [ngClass]="{'on': state == 'home-state' || state == 'research-state'}">
        <div class="header-item small" *ngIf="state == 'home-state'" [ngClass]="{'on': isModalOpen && modal == 'verdict-collab-study'}"
            (click)="setModalById('verdict-collab-study')">
            <span>LEARN MORE ABOUT<br>VERDICT IN UC</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'presentations'}"
            (click)="setModalById('presentations')">
            <span>VIEW OUR SCIENTIFIC<br>PRESENTATIONS</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'symposia'}"
            (click)="setModalById('symposia')">
            <span>VISIT OUR<br>SYMPOSIA</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="home-state header-state" [ngClass]="{'on': state == 'home-state' }">
        <div class="header-item large" [ngClass]="{'on': !isModalOpen && giDirection}"
            (click)="setPhotosphere('hotspotGoToGI')">
            <span>GI CONDITIONS WE TREAT</span>
        </div>
        <div class="header-item large" (click)="setPhotosphere('hotspotGoToRD')">
            <span>RESEARCH & DEVELOPMENT</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="research-state header-state" [ngClass]="{'on': state == 'research-state'}">
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'pipeline'}"
            (click)="setModalById('pipeline')">
            <span>Pipeline<br>Overview</span>
        </div>
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'celiac'}"
            (click)="setModalById('celiac')">
            <span>Celiac<br>Disease</span>
        </div>
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'clinicalTrials'}"
            (click)="setModalById('clinicalTrials')">
            <span>Clinical<br>Trials</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="ulcer-state header-state" [ngClass]="{'on': state == 'ulcer-state'}">
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'ulcerativeColitis'}"
            (click)="setModalById('ulcerativeColitis')">
            <span>ULCERATIVE COLITIS<br>AND CROHN’S DISEASE</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'pathophysiologyUcCd'}"
            (click)="setModalById('pathophysiologyUcCd')">
            <span>PATHOPHYSIOLOGY<br>OF UC AND CD</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'vedolizumab'}"
            (click)="setModalById('vedolizumab')">
            <span>Vedolizumab</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'verdict-collab-study'}"
            (click)="setModalById('verdict-collab-study')">
            <span>VERDICT<br>COLLABORATIVE STUDY</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="short-bowel-state header-state" [ngClass]="{'on': state == 'short-bowel-state'}">
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'shortBowel'}"
            (click)="setModalById('shortBowel')">
            <span>SHORT BOWEL<br>SYNDROME</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'intestinalFunction'}"
            (click)="setModalById('intestinalFunction')">
            <span>The Spectrum of<br>Intestinal Function</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'postParenteral'}"
            (click)="setModalById('postParenteral')">
            <span>Post Parenteral<br>Support in SBS-IF</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'teduglutide'}"
            (click)="setModalById('teduglutide')">
            <span>Teduglutide<br>Mode of Action</span>
        </div>
    </div>
    <div *ngIf="!useCustomHeader" class="perianal-state header-state" [ngClass]="{'on': state == 'perianal-state'}">
        <div class="header-item large" [ngClass]="{'on': isModalOpen && modal == 'chronsPerianal'}"
            (click)="setModalById('chronsPerianal')">
            <span>CROHN’S<br>PERIANAL FISTULAS</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'vid-pathophysiology'}"
            (click)="setModalById('vid-pathophysiology')">
            <span>Pathophysiology</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'mesenchymal'}"
            (click)="setModalById('mesenchymal')">
            <span>Mesenchymal Stem Cells<br>Mode of Action</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'ecco'}"
            (click)="setModalById('ecco')">
            <span>ECCO Treatment<br>Guidelines 2019</span>
        </div>
        <div class="header-item small" [ngClass]="{'on': isModalOpen && modal == 'inspire'}"
            (click)="setModalById('inspire')">
            <span>INSPIRE<br>Registry</span>
        </div>
    </div>
</div>