import { Component, OnInit } from '@angular/core';
import { StudyService } from '../services/study.service';
import { ModalService } from '../services/modal.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-study-results',
    templateUrl: './study-results.component.html',
    styleUrls: ['./study-results.component.scss']
})
export class StudyResultsComponent implements OnInit {
    public results: any = undefined;
    public condition: any;
    public footnote_a: boolean = false;
    public footnote_b: boolean = false;

    public path: string = environment.assetBasePath + 'pdfs/clinical-trials/';

    constructor(private _ss: StudyService, private _ms: ModalService) { }

    ngOnInit(): void {
        this.subscribe();
    }

    public subscribe(): void {
        this._ss.results.subscribe(data => {
            this.results = data;
            this.footnote_a = false;
            this.footnote_b = false;

            for (const r of this.results) {
                if (!this.footnote_a) {
                    this.footnote_a = r.footnotes.includes('a');
                }
                if (!this.footnote_b) {
                    this.footnote_b = r.footnotes.includes('b');
                }
            }
        });

        this._ss.condition.subscribe(data => {
            this.condition = data;
        });
    }

    public openStudy(study): void {
        const data = {
            modal_type: 'pdf',
            id: 'clinicalTrials',
            parent: 'studyResults',
            pdfSrc: this.path + study.study_pdf_path,
            gtm_id: (study.gtm_id) ? study.gtm_id : -1
        }
        this._ms.modalData.next(data);
    }
}
