import { Component, OnInit } from '@angular/core';
import Photospheres from './data/photospheres.json';
import Modals from './data/modals.json';
import { ModalService } from './services/modal.service';
import { PhotosphereService } from './services/photosphere.service';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public currentPhotosphere: any = false;
  public photosphereActive = false;

  public magicKey = true;
  public showWelcome = true;
  public desktop = true;
  public photosphereImgs = [];

  public entryViewed: boolean = false;


  public Actions = [
    null,
    (parameters) => { this.goToPhotosphere(parameters); },
    (parameters) => { this.openModal(parameters); }
  ];

  constructor(private _ps: PhotosphereService, private _ms: ModalService, private activatedRoute: ActivatedRoute, private router: Router) {
    // console.log('app.constructor');

    this.activatedRoute.queryParams.subscribe(params => {
      // this.magicKey = params;
      // if (params?.h && params.h === '2f3a4fccca6406e35bcf33e92dd93135') {
      //   this.magicKey = true;
      //   localStorage.setItem('hi-magic-key', '1');
      //   this.router.navigate(['.'], { queryParams: {} });
      // } else {
      //   this.magicKey = false;
      //   if (localStorage.getItem('hi-magic-key') && localStorage.getItem('hi-magic-key') === '1') {
      //     this.magicKey = true;
      //   }
      // }

      // if (params?.debug && params?.debug === 'true') {
      //   this.disableWelcomeView();
      // }
      if (params?.booth && params.booth === '28dc62a6dd5c635d59a1964fa7946244') {
        this.disableWelcomeView();
      }


      // console.log('The params:', params, this.magicKey);


    });

    if (
      localStorage.getItem('skip-welcome') &&
      localStorage.getItem('skip-welcome') === '1'
    ) {
      this.entryViewed = true;
    }


    // .subscribe(params => {
    //   this.magicKey = params['h'];
    // });

  }


  ngOnInit(): void {
    // console.log('app.init');
    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    this.onWindowResize();
    setTimeout(() => {
      // this.handleOpenPhotosphere({ target: Photospheres[0], rotation: 180 });
    }, 100);


    this._ps.photosphereLoaded.subscribe(data => {
      if (data === true) {
        this.handleOpenPhotosphere({ target: Photospheres[0], rotation: 180 });
      }
    });



    // "mainImageBmp": null,
    // "transparencyImageBmp": null,
  }

  public handleOpenPhotosphere(event: any): void {

    // console.log(event);
    this._ps.rotation.next(event.rotation);
    this._ps.currentPhotosphere.next(event.target);

    setTimeout(() => {
      this.photosphereActive = true;
    }, 500);
  }

  public onWindowResize(): void {
    this.desktop = (window.innerWidth > 980);
  }

  goToPhotosphere(parameters: any): void {
    const rotation = parameters.rotation;
    const target = Photospheres.find(ps => ps.id === parameters.id);
    this.handleOpenPhotosphere({ target: target, rotation: rotation });
  }

  openModal(parameters: any): void {
    this._ms.modalVisible.next(true);
    this._ms.modalData.next(Modals.find(modal => modal.id === parameters.id));
  }

  public handlehidePhotosphere(event: any): void {
    this.photosphereActive = false;
    this.currentPhotosphere = false;
  }

  public disableWelcomeView(): void {
    this.showWelcome = false;
    (document.querySelector('#hiTwilioChat') as HTMLElement).style.display = 'block';
    this._ps.interactable.next(true);
    if (!this.entryViewed) {
      // this.openModal({id: "entryVideo"});
      localStorage.setItem('skip-welcome', '1');
    }

  }
}
