import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor {
  @Input()
  label: string = '';

  @Input()
  public value: any;

  public isChecked: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  select() {
    this.isChecked = !this.isChecked;
    this.onChange(this.value);
  }

  writeValue(value: any) {
    this.isChecked = this.value == value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
