import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Photospheres from '../data/photospheres.json';

@Injectable({
  providedIn: 'root'
})
export class PhotosphereService {
  rotation: BehaviorSubject<number> = new BehaviorSubject(0);
  currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(Photospheres[0]);
  interactable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentRotation: BehaviorSubject<number> = new BehaviorSubject(0);
  photosphereLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }
}
