import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { PhotosphereComponent } from './photosphere/photosphere.component';
import { HeroComponent } from './hero/hero.component';
import { ModalComponent } from './modal/modal.component';
import { FilterComponent } from './filter/filter.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { StudyResultsComponent } from './study-results/study-results.component';
import { VideoMenuComponent } from './video-menu/video-menu.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { DynamicComponentsLoaderComponent } from './dynamic-components-loader/dynamic-components-loader.component';
import { ViewContainerHostDirective } from './directive/view-container-host.directive';
import { QuizComponent } from './quiz/quiz.component';
import { RadioComponent } from './radio/radio.component';
import { ResultTextComponent } from './quiz/result-text/result-text.component';
import { RedirectToExternalLinkModalComponent } from './redirect-to-external-link-modal/redirect-to-external-link-modal.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


@NgModule({
  declarations: [
    AppComponent,
    PhotosphereComponent,
    HeroComponent,
    ModalComponent,
    FilterComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    StudyResultsComponent,
    VideoMenuComponent,
    VideoPlayerComponent,
    DynamicComponentsLoaderComponent,
    ViewContainerHostDirective,
    QuizComponent,
    RadioComponent,
    ResultTextComponent,
    RedirectToExternalLinkModalComponent
  ],
  imports: [
    BrowserModule,
    PdfViewerModule,
    PdfJsViewerModule,
    RouterModule.forRoot([]),
  ],
  providers: [
    { provide: Window, useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
