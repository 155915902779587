<div class="cover" [ngClass]="{'on' : showCover}">
    <div class="preloader">
        <img src="../../assets/preloader.png">
    </div>
</div>

<div class="photosphere" [ngClass]="{'on' : showPhotosphere}">
    <div id="container"></div>
    <!-- <div class="controls-prompt" [ngClass]="{'off' : (motionControls || hasMoved)}"> -->
    <div class="controls-prompt" [ngClass]="{'off' : hasMoved}">
        <div *ngIf="photoSphereInteractive">
            <img class="controls-prompt-icon" src="../../assets/handPrompt.png">
            <p>Drag the view left and right to explore.</p>
        </div>
        <div>
            <div class="photosphere-flag static">
                <svg width="23.131" height="23.626" ViewBox="0 0 23.131 23.626"><path d="M3617.427,347.643h-10.774V346.16h10.774V335.089h1.582V346.16h10.775v1.483h-10.775v11.072h-1.582Z" fill="#FFF" transform="translate(-3606.653 -335.089)"></path></svg>
            </div>
            <p>Click on hotspots to learn more.</p>
        </div>
    </div>
    <a href="#" *ngIf="motionControlsPossible" class="controls" [ngClass]="{'off' : !motionControls}"
        (click)="toggleMotionControls()" (mouseup)="consumeMouseUp($event)">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <!-- <path d="M0 0h48v48h-48z" fill="none"/> -->
            <path
                d="M32.97 5.03c6.53 3.1 11.22 9.45 11.93 16.97h3c-1.02-12.32-11.32-22-23.9-22-.45 0-.88.04-1.33.07l7.63 7.63 2.67-2.67zm-12.51-1.54c-1.17-1.17-3.07-1.17-4.24 0l-12.73 12.73c-1.17 1.17-1.17 3.07 0 4.24l24.04 24.04c1.17 1.17 3.07 1.17 4.24 0l12.73-12.73c1.17-1.17 1.17-3.07 0-4.24l-24.04-24.04zm9.2 38.89l-24.05-24.04 12.73-12.73 24.04 24.04-12.72 12.73zm-14.63.59c-6.53-3.1-11.22-9.45-11.93-16.97h-3c1.02 12.32 11.32 22 23.9 22 .45 0 .88-.04 1.33-.07l-7.63-7.63-2.67 2.67z" />
        </svg>
    </a>
</div>

<!-- <div class="logo-cont">
    <img class="logo" src="../../assets/comcast-business-logo.png">
    <h2 class="title">{{currentPhotosphere.name}}</h2>
</div> -->