<div class="filter-container">
    <h2>Clinical Trials</h2>

    <div class="search">
        <div *ngFor="let dropdown of data.dropdowns" class="search-input select select-container">
            <select [name]="dropdown.id" [id]="dropdown.id" #select (change)="selectChange(select.value, dropdown.id)">
                <ng-container *ngFor="let el of dropdown.opts; let i = index">
                    <option *ngIf="i === 0" value="All" [innerHTML]="el" selected disabled></option>
                    <option *ngIf="i !== 0" [value]="el" [innerHTML]="el"></option>
                </ng-container>
            </select>
        </div>

        <div class="search-input search-btn select-container" (click)="handleSearch()">
            <span>Search</span>
        </div>

        <div [ngClass]="{'on': noResults}" class="no-results">
            <p>Your search results didn't match any trials. Try updating the criteria and searching again.</p>
        </div>
    </div>

    <div class="condition-links">
        <div *ngFor="let link of data.main_links" class="condition-link" (click)="conditionClick(link)">
            <span [innerHTML]="link.id"></span>
            <div class="icon"></div>
        </div>
    </div>

    <div class="legal">
        <p>These studies are part of a GI Research investigational program and the indications and/or drug
            administration may not be according to any approved use of the product and not recommended for clinical
            practice.</p>
        <p>VV-MEDMAT-22100 Date of preparation: September 2020</p>
    </div>
</div>