<div class="footer" [ngClass]="{'extended': extendedFooter}">
    <div class="footer-left">
        <p class="footer-definition" [innerHTML]="definitionText"></p>
    </div>

    <div class="footer-right">
        <p class="legal">All rights reserved. June 2021 VV-MEDMAT-47811</p>
    </div>

    <div class="footer-bar" *ngIf="!extendedFooter">
        <p class="footer-intent">
            The information on this website is intended for Healthcare Prescribers Only.</p>
        <div class="footer-buttons">
            <a href="#" (click)="setModalById('productInfo')">PRODUCT INFORMATION</a>
            <a href="#" (click)="medicalRequest()">MEDICAL INFORMATION REQUEST</a>
        </div>
    </div>
    <div class="footer-bar" *ngIf="extendedFooter">
        <div class="footer-intent">
            <p>The information on this website is intended for Healthcare Professionals outside of the USA and UK only.</p>
            <p>Takeda does not have any approved treatment for Developmental and Epileptic Encephalopathies, including Dravet and Lennox-Gastaut Syndromes.  ©2021 Takeda Pharmaceutical Company Limited. All rights reserved. TAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited.</p>

        </div>
        <div class="footer-buttons">
            <a href="#" (click)="medicalRequest()">MEDICAL INFORMATION <br>REQUEST FORM</a>
        </div>
    </div>
</div>

